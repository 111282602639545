import moment from 'moment'

export const setIndicatorsVisible = (tvWidget, IndicatorId, visible) => {
	try {
		tvWidget.activeChart().getStudyById(IndicatorId).setVisible(visible)
		return true
	} catch (error) {
		console.error(
			`Error setIndicatorsVisible ${error.message}`,
			`-> widget: ${!!tvWidget} IndicatorId: ${IndicatorId}`
		)
		return false
	}
}

export const getAllIndicators = tvWidget => {
	try {
		const values = tvWidget.activeChart().getInputValues()
		console.log('>>>> values', values)
	} catch (error) {
		console.error(`Error getAllIndicators ${error.message}`, `-> widget: ${!!tvWidget}`)
	}
}

export const createIndicator = (tvWidget, name, inputs, overrides = {}, options = null) => {
	return tvWidget.activeChart().createStudy(name, false, false, inputs, overrides, options)
}

export const changeChartRange = (resolution, days, tvWidget) => {
	tvWidget.activeChart().setResolution(resolution, () => {
		tvWidget.activeChart()?.setVisibleRange({
			from: moment(new Date()).subtract(days, 'days').unix(),
			to: moment(new Date()).unix(),
		})
	})
}

export const CHART_TYPE = Object.freeze({
	CANDLE: 1,
	LINE: 3,
})

// https://www.tradingcode.net/tradingview/resolution-time-frame-setting/
export const CHART_TIME = Object.freeze({
	TIME_1: '1',
	TIME_5: '5',
	TIME_15: '15',
	TIME_30: '30',
	TIME_1_H: '60',
	TIME_2_H: '120',
	TIME_4_H: '240',
	TIME_8_H: '480',
	TIME_1_D: '1D',
	TIME_1_W: '1W',
	TIME_1_M: '1M',
})

export const TIMEZONES = [
	{ name: 'Africa/Cairo', hour: -2 },
	{ name: 'Africa/Johannesburg', hour: -2 },
	{ name: 'Africa/Lagos', hour: -1 },
	{ name: 'America/Argentina/Buenos_Aires', hour: 3 },
	{ name: 'America/Bogota', hour: 5 },
	{ name: 'America/Caracas', hour: 4 },
	{ name: 'America/Chicago', hour: 6 },
	{ name: 'America/El_Salvador', hour: 6 },
	{ name: 'America/Juneau', hour: 9 },
	{ name: 'America/Lima', hour: 5 },
	{ name: 'America/Los_Angeles', hour: 8 },
	{ name: 'America/Mexico_City', hour: 6 },
	{ name: 'America/New_York', hour: 5 },
	{ name: 'America/Phoenix', hour: 7 },
	{ name: 'America/Santiago', hour: 3 },
	{ name: 'America/Sao_Paulo', hour: 3 },
	{ name: 'America/Toronto', hour: 5 },
	{ name: 'America/Vancouver', hour: 8 },
	{ name: 'Asia/Almaty', hour: -6 },
	{ name: 'Asia/Ashkhabad', hour: -5 },
	{ name: 'Asia/Bahrain', hour: -3 },
	{ name: 'Asia/Bangkok', hour: -7 },
	{ name: 'Asia/Chongqing', hour: -8 },
	{ name: 'Asia/Dubai', hour: -4 },
	{ name: 'Asia/Ho_Chi_Minh', hour: -7 },
	{ name: 'Asia/Hong_Kong', hour: -8 },
	{ name: 'Asia/Jakarta', hour: -7 },
	{ name: 'Asia/Jerusalem', hour: -2 },
	{ name: 'Asia/Kathmandu', hour: -5.75 },
	{ name: 'Asia/Kolkata', hour: -5.5 },
	{ name: 'Asia/Kuwait', hour: -3 },
	{ name: 'Asia/Muscat', hour: -4 },
	{ name: 'Asia/Qatar', hour: -3 },
	{ name: 'Asia/Riyadh', hour: -3 },
	{ name: 'Asia/Seoul', hour: -9 },
	{ name: 'Asia/Shanghai', hour: -8 },
	{ name: 'Asia/Singapore', hour: -8 },
	{ name: 'Asia/Taipei', hour: -8 },
	{ name: 'Asia/Tehran', hour: -3.5 },
	{ name: 'Asia/Tokyo', hour: -9 },
	{ name: 'Atlantic/Reykjavik', hour: 0 },
	{ name: 'Australia/ACT', hour: -9.5 },
	{ name: 'Australia/Adelaide', hour: -10.5 },
	{ name: 'Australia/Brisbane', hour: -10 },
	{ name: 'Australia/Perth', hour: -8 },
	{ name: 'Australia/Sydney', hour: -11 },
	{ name: 'Europe/Amsterdam', hour: -1 },
	{ name: 'Europe/Athens', hour: -2 },
	{ name: 'Europe/Belgrade', hour: -1 },
	{ name: 'Europe/Berlin', hour: -1 },
	{ name: 'Europe/Brussels', hour: -1 },
	{ name: 'Europe/Copenhagen', hour: -1 },
	{ name: 'Europe/Dublin', hour: 0 },
	{ name: 'Europe/Helsinki', hour: -2 },
	{ name: 'Europe/Istanbul', hour: -3 },
	{ name: 'Europe/Lisbon', hour: 0 },
	{ name: 'Europe/London', hour: 0 },
	{ name: 'Europe/Luxembourg', hour: -1 },
	{ name: 'Europe/Madrid', hour: -1 },
	{ name: 'Europe/Malta', hour: -1 },
	{ name: 'Europe/Moscow', hour: -3 },
	{ name: 'Europe/Oslo', hour: -1 },
	{ name: 'Europe/Paris', hour: -1 },
	{ name: 'Europe/Riga', hour: -2 },
	{ name: 'Europe/Rome', hour: -1 },
	{ name: 'Europe/Stockholm', hour: -1 },
	{ name: 'Europe/Tallinn', hour: -2 },
	{ name: 'Europe/Vilnius', hour: -2 },
	{ name: 'Europe/Warsaw', hour: -1 },
	{ name: 'Europe/Zurich', hour: -1 },
	{ name: 'Pacific/Auckland', hour: -13 },
	{ name: 'Pacific/Chatham', hour: -13.75 },
	{ name: 'Pacific/Fakaofo', hour: -13 },
	{ name: 'Pacific/Honolulu', hour: 10 },
	{ name: 'Pacific/Norfolk', hour: -12 },
	{ name: 'US/Mountain', hour: 7 },
]
