import historyProvider, { resolveResolution } from './historyProvider'
import EventsClient from '../../../services/grpcweb/EventsClient'
import moment from 'moment'

let stream = new EventsClient()

let activeSub = null

let countErrors = 0

const initStream = (symbolInfo, subscribeUID, resolution, onRealtimeCallback) => {
	// starts data stream to update chart
	stream.subscribeTickers(symbolInfo?.ticker, resolveResolution(resolution).id)

	const newSub = {
		channelString: symbolInfo?.name,
		uid: subscribeUID,
		resolution,
		symbolInfo,
		lastBar: historyProvider.history[symbolInfo?.name].lastBar,
		listener: onRealtimeCallback,
	}

	activeSub = newSub

	// when some data is received, it calls the function to update the chart
	// (the trading view handles the data automatically)
	stream.onData(data => updateChart(data, onRealtimeCallback))

	// try reconnect stream on error
	stream.onError(async () => {
		if (countErrors < 5) {
			await stream.cancelStream()
			reconnectStream(symbolInfo, subscribeUID, resolution, onRealtimeCallback)
		}
	})
}

const reconnectStream = (symbolInfo, subscribeUID, resolution, onRealtimeCallback) => {
	countErrors = countErrors + 1
	stream = new EventsClient()
	initStream(symbolInfo, subscribeUID, resolution, onRealtimeCallback)
}

export const updateChart = (data, callback) => {
	// add the new candle on the chart by callback
	const sub = activeSub?.channelString === data?.KeypairName
	// check if the event received is from the selected keypair

	if (sub) {
		const candle = {
			time:
				Date.now() -
				moment(new Date()).diff(new Date(data?.Tickers?.TickerTime?.Seconds * 1000), 'milliseconds'),
			low: data?.Tickers?.Low,
			high: data?.Tickers?.High,
			open: data?.Tickers?.Open,
			close: data?.Tickers?.Close,
			volume: data?.Tickers?.Volume,
		}
		// add the new candle on the chart by callback
		return callback(candle)
	}
}

const DataFeed = config => ({
	onReady: cb => {
		// the chart starts here and passes the configuration object to the callback
		setTimeout(() => cb(config), 0)
		// The charting library wants this to be executed asynchronously, and suggests wrapping in setTimeout with delay of 0 to force this behavior
	},
	searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
		// in our case, the symbol search function is not activated, so it is not necessary to do anything here
	},
	resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
		// find the keypair and set the symbol on the chart.
		// after that the chart is ready to receive tickers and be rendered

		const keypair = JSON.parse(symbolName) // symbolName is a JSON of selectedKeypair object from state

		const symbolStub = {
			name: keypair?.Name || keypair,
			description: '',
			type: 'crypto',
			session: '24x7',
			timezone: 'UTC',
			ticker: keypair?.ID || keypair,
			exchange: 'KLEVER',
			minmov: 1,
			pricescale: Math.pow(10, keypair?.Precision || 8),
			has_intraday: true,
			supported_resolution: config.supportedResolutions,
			volume_precision: 8,
			data_status: 'streaming',
			has_emtpy_bars: true,
		}

		// timeout to force asynchronously execution
		setTimeout(() => {
			if (keypair?.Name) {
				onSymbolResolvedCallback(symbolStub)
			}
		}, 0)
	},
	getBars: function (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
		// search for tickers in the api
		// is called at chart init, after that the data will be updated by the stream (subscribeBars)
		historyProvider
			.getBars(symbolInfo, resolution, periodParams)
			.then(bars => {
				if (bars.length) {
					onHistoryCallback(bars, { noData: false })
				} else {
					onHistoryCallback(bars, { noData: true })
				}
			})
			.catch(err => {
				onErrorCallback(err)
			})
	},
	subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
		initStream(symbolInfo, subscribeUID, resolution, onRealtimeCallback)
	},
	unsubscribeBars: subscriberUID => {
		if (activeSub?.uid === subscriberUID) {
			activeSub = null
			stream.cancelStream()
		}
	},
})

export default DataFeed
