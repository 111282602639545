import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../PopupButton'

const Container = styled.div`
	display: flex;
	overflow-y: hidden;
	flex-direction: row;
	justify-content: space-around;
	width: 250px;
	padding: 8px;
	z-index: 2;
	font-family: 'Montserrat', sans-serif;
`

const Section = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
`

const OptionsSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 8px 0 8px 0;
`

const Fixed = styled.span`
	color: #aaa;
	font-size: 14px;
`

const MobileIndicatorsSelector = ({ mainIndicators, onSelectMainIndicator, onHideMainIndicators }) => {
	return (
		<Container>
			<Section>
				<Fixed>Main</Fixed>
				<OptionsSection>
					{mainIndicators.map(ind => (
						<Button
							key={ind.label}
							label={ind.label}
							isSelected={ind.isSelected}
							onClick={() => {
								onSelectMainIndicator(ind)
							}}
						/>
					))}
				</OptionsSection>
				<Button
					label='Hide'
					onClick={() => {
						onHideMainIndicators()
					}}
				/>
			</Section>
		</Container>
	)
}

MobileIndicatorsSelector.propTypes = {
	mainIndicators: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.string,
			isSelected: PropTypes.bool,
		})
	).isRequired,
	onHideMainIndicators: PropTypes.func.isRequired,
	onSelectMainIndicator: PropTypes.func.isRequired,
}
MobileIndicatorsSelector.defaultProps = {
	onHideMainIndicators: () => {},
	onSelectMainIndicator: () => {},
}

export default MobileIndicatorsSelector
