import moment from 'moment'

import { fetchTickers } from '../../../services/manager'

const history = {}

// converts tradingview resolution to our api format
export const resolveResolution = res => {
	switch (String(res).toUpperCase()) {
		case '1':
			return { id: 0, value: 'MINUTE_1' }
		case '5':
			return { id: 8, value: 'MINUTE_5' }
		case '15':
			return { id: 1, value: 'MINUTE_15' }
		case '30':
			return { id: 9, value: 'MINUTE_30' }
		case '60':
			return { id: 2, value: 'HOUR_1' }
		case '120':
			return { id: 10, value: 'HOUR_2' }
		case '240':
			return { id: 3, value: 'HOUR_4' }
		case '480':
			return { id: 11, value: 'HOUR_8' }
		case '1D':
			return { id: 4, value: 'DAILY' }
		case '1W':
			return { id: 5, value: 'WEEKLY' }
		case '1M':
			return { id: 6, value: 'MONTHLY' }
		case '1Y':
			return { id: 7, value: 'YEARLY' }
		default:
			return { id: 0, value: 'MINUTE_1' }
	}
}

export default {
	history: history,
	getBars: (symbolInfo, resolution, periodParams) => {
		// periodParams.countBack is the number of tickets needed to fill the chart at the specified resolution
		const { firstDataRequest } = periodParams

		return fetchTickers(symbolInfo?.ticker, resolveResolution(resolution).value, periodParams).then(data => {
			if (typeof data !== 'object') {
				return []
			}
			if (data.length) {
				let bars = []

				for (const ticker of data) {
					const newBar = {
						time: new Date(ticker?.TickerTime).getTime(),
						low: ticker?.Low,
						high: ticker?.High,
						open: ticker?.Open,
						close: ticker?.Close,
						volume: ticker?.Volume,
					}

					const barTime = newBar.time / 1000

					if (!firstDataRequest) {
						if (!moment(ticker?.TickerTime).isSame(new Date(), 'day')) {
							if (barTime <= periodParams.to) {
								bars = [...bars, newBar]
							}
						}
					} else {
						bars = [...bars, newBar]
					}
				}
				if (bars.length) {
					const lastBar = bars[bars.length - 1]
					history[symbolInfo?.name] = { lastBar: lastBar }
					return bars
				}
			}
			return []
		})
	},
}
