/**
 * @fileoverview gRPC-Web generated client stub for events
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

const grpc = {}
grpc.web = require('grpc-web')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_api_annotations_pb = require('./google/api/annotations_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {}
proto.events = require('./events_pb.js')

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.events.EventsServiceClient = function (hostname, credentials, options) {
	if (!options) options = {}
	options['format'] = 'text'

	/**
	 * @private @const {!grpc.web.GrpcWebClientBase} The client
	 */
	this.client_ = new grpc.web.GrpcWebClientBase(options)

	/**
	 * @private @const {string} The hostname
	 */
	this.hostname_ = hostname
}

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.events.EventsServicePromiseClient = function (hostname, credentials, options) {
	if (!options) options = {}
	options['format'] = 'text'

	/**
	 * @private @const {!grpc.web.GrpcWebClientBase} The client
	 */
	this.client_ = new grpc.web.GrpcWebClientBase(options)

	/**
	 * @private @const {string} The hostname
	 */
	this.hostname_ = hostname
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.events.ConnectOrders,
 *   !proto.events.StreamOrdersResponse>}
 */
const methodDescriptor_EventsService_SubscribeOrders = new grpc.web.MethodDescriptor(
	'/events.EventsService/SubscribeOrders',
	grpc.web.MethodType.SERVER_STREAMING,
	proto.events.ConnectOrders,
	proto.events.StreamOrdersResponse,
	/**
	 * @param {!proto.events.ConnectOrders} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.events.StreamOrdersResponse.deserializeBinary
)

// /**
//  * @const
//  * @type {!grpc.web.AbstractClientBase.MethodInfo<
//  *   !proto.events.ConnectOrders,
//  *   !proto.events.StreamOrdersResponse>}
//  */
// const methodInfo_EventsService_SubscribeOrders = new grpc.web.AbstractClientBase.MethodInfo(
//   proto.events.StreamOrdersResponse,
//   /**
//    * @param {!proto.events.ConnectOrders} request
//    * @return {!Uint8Array}
//    */
//   function(request) {
//     return request.serializeBinary();
//   },
//   proto.events.StreamOrdersResponse.deserializeBinary
// );

/**
 * @param {!proto.events.ConnectOrders} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.events.StreamOrdersResponse>}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServiceClient.prototype.subscribeOrders = function (request, metadata) {
	return this.client_.serverStreaming(
		this.hostname_ + '/events.EventsService/SubscribeOrders',
		request,
		metadata || {},
		methodDescriptor_EventsService_SubscribeOrders
	)
}

/**
 * @param {!proto.events.ConnectOrders} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.events.StreamOrdersResponse>}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServicePromiseClient.prototype.subscribeOrders = function (request, metadata) {
	return this.client_.serverStreaming(
		this.hostname_ + '/events.EventsService/SubscribeOrders',
		request,
		metadata || {},
		methodDescriptor_EventsService_SubscribeOrders
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.events.ConnectStreamTicker,
 *   !proto.events.StreamTickerResponse>}
 */
const methodDescriptor_EventsService_SubscribeTickers = new grpc.web.MethodDescriptor(
	'/events.EventsService/SubscribeTickers',
	grpc.web.MethodType.SERVER_STREAMING,
	proto.events.ConnectStreamTicker,
	proto.events.StreamTickerResponse,
	/**
	 * @param {!proto.events.ConnectStreamTicker} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.events.StreamTickerResponse.deserializeBinary
)

// /**
//  * @const
//  * @type {!grpc.web.AbstractClientBase.MethodInfo<
//  *   !proto.events.ConnectStreamTicker,
//  *   !proto.events.StreamTickerResponse>}
//  */
// const methodInfo_EventsService_SubscribeTickers = new grpc.web.AbstractClientBase.MethodInfo(
//   proto.events.StreamTickerResponse,
//   /**
//    * @param {!proto.events.ConnectStreamTicker} request
//    * @return {!Uint8Array}
//    */
//   function(request) {
//     return request.serializeBinary();
//   },
//   proto.events.StreamTickerResponse.deserializeBinary
// );

/**
 * @param {!proto.events.ConnectStreamTicker} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.events.StreamTickerResponse>}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServiceClient.prototype.subscribeTickers = function (request, metadata) {
	return this.client_.serverStreaming(
		this.hostname_ + '/events.EventsService/SubscribeTickers',
		request,
		metadata || {},
		methodDescriptor_EventsService_SubscribeTickers
	)
}

/**
 * @param {!proto.events.ConnectStreamTicker} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.events.StreamTickerResponse>}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServicePromiseClient.prototype.subscribeTickers = function (request, metadata) {
	return this.client_.serverStreaming(
		this.hostname_ + '/events.EventsService/SubscribeTickers',
		request,
		metadata || {},
		methodDescriptor_EventsService_SubscribeTickers
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.events.ConnectOrderbookStatus,
 *   !proto.events.StreamOrderbookStatusResponse>}
 */
const methodDescriptor_EventsService_SubscribeOrderbookStatus = new grpc.web.MethodDescriptor(
	'/events.EventsService/SubscribeOrderbookStatus',
	grpc.web.MethodType.SERVER_STREAMING,
	proto.events.ConnectOrderbookStatus,
	proto.events.StreamOrderbookStatusResponse,
	/**
	 * @param {!proto.events.ConnectOrderbookStatus} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.events.StreamOrderbookStatusResponse.deserializeBinary
)

// /**
//  * @const
//  * @type {!grpc.web.AbstractClientBase.MethodInfo<
//  *   !proto.events.ConnectOrderbookStatus,
//  *   !proto.events.StreamOrderbookStatusResponse>}
//  */
// const methodInfo_EventsService_SubscribeOrderbookStatus = new grpc.web.AbstractClientBase.MethodInfo(
//   proto.events.StreamOrderbookStatusResponse,
//   /**
//    * @param {!proto.events.ConnectOrderbookStatus} request
//    * @return {!Uint8Array}
//    */
//   function(request) {
//     return request.serializeBinary();
//   },
//   proto.events.StreamOrderbookStatusResponse.deserializeBinary
// );

/**
 * @param {!proto.events.ConnectOrderbookStatus} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.events.StreamOrderbookStatusResponse>}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServiceClient.prototype.subscribeOrderbookStatus = function (request, metadata) {
	return this.client_.serverStreaming(
		this.hostname_ + '/events.EventsService/SubscribeOrderbookStatus',
		request,
		metadata || {},
		methodDescriptor_EventsService_SubscribeOrderbookStatus
	)
}

/**
 * @param {!proto.events.ConnectOrderbookStatus} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.events.StreamOrderbookStatusResponse>}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServicePromiseClient.prototype.subscribeOrderbookStatus = function (request, metadata) {
	return this.client_.serverStreaming(
		this.hostname_ + '/events.EventsService/SubscribeOrderbookStatus',
		request,
		metadata || {},
		methodDescriptor_EventsService_SubscribeOrderbookStatus
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.events.ConnectGetKeypairs,
 *   !proto.events.StreamGetKeypairsResponse>}
 */
const methodDescriptor_EventsService_SubscribeGetKeypairs = new grpc.web.MethodDescriptor(
	'/events.EventsService/SubscribeGetKeypairs',
	grpc.web.MethodType.SERVER_STREAMING,
	proto.events.ConnectGetKeypairs,
	proto.events.StreamGetKeypairsResponse,
	/**
	 * @param {!proto.events.ConnectGetKeypairs} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.events.StreamGetKeypairsResponse.deserializeBinary
)

// /**
//  * @const
//  * @type {!grpc.web.AbstractClientBase.MethodInfo<
//  *   !proto.events.ConnectGetKeypairs,
//  *   !proto.events.StreamGetKeypairsResponse>}
//  */
// const methodInfo_EventsService_SubscribeGetKeypairs = new grpc.web.AbstractClientBase.MethodInfo(
//   proto.events.StreamGetKeypairsResponse,
//   /**
//    * @param {!proto.events.ConnectGetKeypairs} request
//    * @return {!Uint8Array}
//    */
//   function(request) {
//     return request.serializeBinary();
//   },
//   proto.events.StreamGetKeypairsResponse.deserializeBinary
// );

/**
 * @param {!proto.events.ConnectGetKeypairs} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.events.StreamGetKeypairsResponse>}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServiceClient.prototype.subscribeGetKeypairs = function (request, metadata) {
	return this.client_.serverStreaming(
		this.hostname_ + '/events.EventsService/SubscribeGetKeypairs',
		request,
		metadata || {},
		methodDescriptor_EventsService_SubscribeGetKeypairs
	)
}

/**
 * @param {!proto.events.ConnectGetKeypairs} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.events.StreamGetKeypairsResponse>}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServicePromiseClient.prototype.subscribeGetKeypairs = function (request, metadata) {
	return this.client_.serverStreaming(
		this.hostname_ + '/events.EventsService/SubscribeGetKeypairs',
		request,
		metadata || {},
		methodDescriptor_EventsService_SubscribeGetKeypairs
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.events.ConnectBalances,
 *   !proto.events.StreamBalancesResponse>}
 */
const methodDescriptor_EventsService_SubscribeBalances = new grpc.web.MethodDescriptor(
	'/events.EventsService/SubscribeBalances',
	grpc.web.MethodType.SERVER_STREAMING,
	proto.events.ConnectBalances,
	proto.events.StreamBalancesResponse,
	/**
	 * @param {!proto.events.ConnectBalances} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.events.StreamBalancesResponse.deserializeBinary
)

// /**
//  * @const
//  * @type {!grpc.web.AbstractClientBase.MethodInfo<
//  *   !proto.events.ConnectBalances,
//  *   !proto.events.StreamBalancesResponse>}
//  */
// const methodInfo_EventsService_SubscribeBalances = new grpc.web.AbstractClientBase.MethodInfo(
//   proto.events.StreamBalancesResponse,
//   /**
//    * @param {!proto.events.ConnectBalances} request
//    * @return {!Uint8Array}
//    */
//   function(request) {
//     return request.serializeBinary();
//   },
//   proto.events.StreamBalancesResponse.deserializeBinary
// );

/**
 * @param {!proto.events.ConnectBalances} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.events.StreamBalancesResponse>}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServiceClient.prototype.subscribeBalances = function (request, metadata) {
	return this.client_.serverStreaming(
		this.hostname_ + '/events.EventsService/SubscribeBalances',
		request,
		metadata || {},
		methodDescriptor_EventsService_SubscribeBalances
	)
}

/**
 * @param {!proto.events.ConnectBalances} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.events.StreamBalancesResponse>}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServicePromiseClient.prototype.subscribeBalances = function (request, metadata) {
	return this.client_.serverStreaming(
		this.hostname_ + '/events.EventsService/SubscribeBalances',
		request,
		metadata || {},
		methodDescriptor_EventsService_SubscribeBalances
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.events.ConnectOrderGroups,
 *   !proto.events.StreamOrderGroupsResponse>}
 */
const methodDescriptor_EventsService_SubscribeOrderGroups = new grpc.web.MethodDescriptor(
	'/events.EventsService/SubscribeOrderGroups',
	grpc.web.MethodType.SERVER_STREAMING,
	proto.events.ConnectOrderGroups,
	proto.events.StreamOrderGroupsResponse,
	/**
	 * @param {!proto.events.ConnectOrderGroups} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.events.StreamOrderGroupsResponse.deserializeBinary
)

// /**
//  * @const
//  * @type {!grpc.web.AbstractClientBase.MethodInfo<
//  *   !proto.events.ConnectOrderGroups,
//  *   !proto.events.StreamOrderGroupsResponse>}
//  */
// const methodInfo_EventsService_SubscribeOrderGroups = new grpc.web.AbstractClientBase.MethodInfo(
//   proto.events.StreamOrderGroupsResponse,
//   /**
//    * @param {!proto.events.ConnectOrderGroups} request
//    * @return {!Uint8Array}
//    */
//   function(request) {
//     return request.serializeBinary();
//   },
//   proto.events.StreamOrderGroupsResponse.deserializeBinary
// );

/**
 * @param {!proto.events.ConnectOrderGroups} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.events.StreamOrderGroupsResponse>}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServiceClient.prototype.subscribeOrderGroups = function (request, metadata) {
	return this.client_.serverStreaming(
		this.hostname_ + '/events.EventsService/SubscribeOrderGroups',
		request,
		metadata || {},
		methodDescriptor_EventsService_SubscribeOrderGroups
	)
}

/**
 * @param {!proto.events.ConnectOrderGroups} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.events.StreamOrderGroupsResponse>}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServicePromiseClient.prototype.subscribeOrderGroups = function (request, metadata) {
	return this.client_.serverStreaming(
		this.hostname_ + '/events.EventsService/SubscribeOrderGroups',
		request,
		metadata || {},
		methodDescriptor_EventsService_SubscribeOrderGroups
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.events.ConnectFills,
 *   !proto.events.StreamFillsResponse>}
 */
const methodDescriptor_EventsService_SubscribeFills = new grpc.web.MethodDescriptor(
	'/events.EventsService/SubscribeFills',
	grpc.web.MethodType.SERVER_STREAMING,
	proto.events.ConnectFills,
	proto.events.StreamFillsResponse,
	/**
	 * @param {!proto.events.ConnectFills} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.events.StreamFillsResponse.deserializeBinary
)

// /**
//  * @const
//  * @type {!grpc.web.AbstractClientBase.MethodInfo<
//  *   !proto.events.ConnectFills,
//  *   !proto.events.StreamFillsResponse>}
//  */
// const methodInfo_EventsService_SubscribeFills = new grpc.web.AbstractClientBase.MethodInfo(
//   proto.events.StreamFillsResponse,
//   /**
//    * @param {!proto.events.ConnectFills} request
//    * @return {!Uint8Array}
//    */
//   function(request) {
//     return request.serializeBinary();
//   },
//   proto.events.StreamFillsResponse.deserializeBinary
// );

/**
 * @param {!proto.events.ConnectFills} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.events.StreamFillsResponse>}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServiceClient.prototype.subscribeFills = function (request, metadata) {
	return this.client_.serverStreaming(
		this.hostname_ + '/events.EventsService/SubscribeFills',
		request,
		metadata || {},
		methodDescriptor_EventsService_SubscribeFills
	)
}

/**
 * @param {!proto.events.ConnectFills} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.events.StreamFillsResponse>}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServicePromiseClient.prototype.subscribeFills = function (request, metadata) {
	return this.client_.serverStreaming(
		this.hostname_ + '/events.EventsService/SubscribeFills',
		request,
		metadata || {},
		methodDescriptor_EventsService_SubscribeFills
	)
}

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.events.HealthCheckResponse>}
 */
const methodDescriptor_EventsService_HealthCheck = new grpc.web.MethodDescriptor(
	'/events.EventsService/HealthCheck',
	grpc.web.MethodType.UNARY,
	google_protobuf_empty_pb.Empty,
	proto.events.HealthCheckResponse,
	/**
	 * @param {!proto.google.protobuf.Empty} request
	 * @return {!Uint8Array}
	 */
	function (request) {
		return request.serializeBinary()
	},
	proto.events.HealthCheckResponse.deserializeBinary
)

// /**
//  * @const
//  * @type {!grpc.web.AbstractClientBase.MethodInfo<
//  *   !proto.google.protobuf.Empty,
//  *   !proto.events.HealthCheckResponse>}
//  */
// const methodInfo_EventsService_HealthCheck = new grpc.web.AbstractClientBase.MethodInfo(
//   proto.events.HealthCheckResponse,
//   /**
//    * @param {!proto.google.protobuf.Empty} request
//    * @return {!Uint8Array}
//    */
//   function(request) {
//     return request.serializeBinary();
//   },
//   proto.events.HealthCheckResponse.deserializeBinary
// );

/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.events.HealthCheckResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.events.HealthCheckResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.events.EventsServiceClient.prototype.healthCheck = function (request, metadata, callback) {
	return this.client_.rpcCall(
		this.hostname_ + '/events.EventsService/HealthCheck',
		request,
		metadata || {},
		methodDescriptor_EventsService_HealthCheck,
		callback
	)
}

/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.events.HealthCheckResponse>}
 *     Promise that resolves to the response
 */
proto.events.EventsServicePromiseClient.prototype.healthCheck = function (request, metadata) {
	return this.client_.unaryCall(
		this.hostname_ + '/events.EventsService/HealthCheck',
		request,
		metadata || {},
		methodDescriptor_EventsService_HealthCheck
	)
}

module.exports = proto.events
