export const INDICATORS_COLORS = {
	MA_9_0: '#decc04',
	MA_12_0: '#bc00c9',
	MA_30_0: '#0a57c2',
	EMA_9_0: '#decc04',
	EMA_12_0: '#bc00c9',
	EMA_30_0: '#0a57c2',
	BOLL: '#decc04',
	ICHIMOKU: '#FFF',
}

export const mobileOptions = {
	disabled_features: [
		'use_localstorage_for_settings',
		'header_symbol_search',
		'header_resolutions',
		'header_compare',
		'header_chart_type',
		'header_saveload',
		'header_settings',
		'header_indicators',
		'header_undo_redo',
		'header_screenshot',
		'header_fullscreen_button',
		'display_market_status',
		'main_series_scale_menu',
		'legend_widget',
		'edit_buttons_in_legend',
		'legend_context_menu',
		'study_templates',
		'go_to_date',
		'header_widget',
		'save_shortcut',
		'save_chart_properties_to_local_storage',
		'symbol_info',
		'symbol_search_hot_key',
		'left_toolbar',
		'control_bar',
		'timeframes_toolbar',
		'show_trading_notifications_history',
		'support_multicharts',
		'header_layouttoggle',
		'open_account_manager',
		'right_toolbar',
		'trading_account_manager',
		'show_object_tree',
		'popup_hints',
		'volume_force_overlay',
		'order_panel',
	],
	enabled_features: [],
	fullscreen: false,
	autosize: true,
	theme: 'Dark',
	custom_css_url: 'chart_mobile.css',
	loading_screen: {
		backgroundColor: '#1e1e26',
		foregroundColor: '#d96200',
	},
	logo: {
		image: '/bitcoinme.png',
		link: 'https://bitcoin.me',
	},
	overrides: {
		'paneProperties.vertGridProperties.color': 'rgba(44, 44, 44, 0.07)',
		'paneProperties.horzGridProperties.color': 'rgba(44, 44, 44, 0.07)',
		'paneProperties.backgroundType': 'solid',
		'paneProperties.background': '#1e1e26',
		'mainSeriesProperties.lineStyle.color': '#d96200',
		'mainSeriesProperties.areaStyle.color1': 'rgba(170,51,181, 0.28)',
		'mainSeriesProperties.areaStyle.color2': '#d96200',
		'mainSeriesProperties.areaStyle.linecolor': '#d96200',
	},
}

export const mobileDataFeedConfig = {
	supported_resolutions: ['1', '5', '15', '30', '60', '120', '240', '480', '1D', '1W', '1M'],
}
