import axios from 'axios'
import get from 'lodash/get'

const MANAGER_API = process.env.REACT_APP_MANAGER_API

const MANAGER = axios.create({
	baseURL: MANAGER_API,
})

export const fetchTickers = async (keypairID = 0, timeRange = 'DAILY', periodParams) => {
	try {
		let params = {
			KeypairID: keypairID,
			Limit: 1000,
			TimeRange: timeRange,
		}

		const thirtyDays = 2592000

		// if (!periodParams?.firstDataRequest) {
		params = {
			...params,
			Limit: periodParams?.countBack + 1,
			'TimeInterval.Begin': new Date((periodParams.from - thirtyDays) * 1000),
			'TimeInterval.Ends': new Date(periodParams.to * 1000),
		}
		// }

		if (new Date((periodParams.from - thirtyDays) * 1000).getTime() < 1632960000000) {
			return []
		}

		const response = await MANAGER.get(`tickers`, { params })
		return get(response, 'data.Tickers', [])
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchTickers logError')
		console.log(location.pathname, 'klv_fetchTickers_error', errorResponse)
		return errorResponse
	}
}
