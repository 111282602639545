import { ConnectStreamTicker, ConnectOrderbookStatus } from './protoBuffers/events_pb.js'
import GenericClient from './GenericClient.js'
class EventsClient extends GenericClient {
	constructor() {
		super()
		this.busy = false
	}

	// Subscription Controllers
	subscribeOrderbookStatus(keypairId = 0, currency = 0, metadata = {}) {
		const request = new ConnectOrderbookStatus()
		request.setCurrency(currency)
		request.setKeypairId(keypairId)

		this.stream = this.eventsService.subscribeOrderbookStatus(request, metadata)
		this.busy = true
	}

	subscribeTickers(keypairId = 0, timerange = 0, metadata = {}) {
		const request = new ConnectStreamTicker()
		request.setTimeRange(timerange)
		request.setKeypairId(keypairId)

		this.stream = this.eventsService.subscribeTickers(request, metadata)
		this.busy = true
	}

	// Event Handlers
	onEnd(callback) {
		this.stream.on('end', callback)
		this.busy = false
	}

	onCancel(callback) {
		this.stream.on('cancel', callback)
		this.busy = false
	}

	onError(callback) {
		this.stream.on('error', callback)
	}

	onData(callback) {
		this.stream.on('data', response => {
			callback(this.serializeResponse(response))
		})
	}

	onStatus(callback) {
		this.stream.on('status', status => {
			console.log('status.code', status.code)
			console.log('status.details', status.details)
			console.log('status.metadata', status.metadata)
		})
	}

	cancelStream(callback) {
		this.stream.cancel()
		if (callback && typeof callback === 'function') callback()
	}
}

export default EventsClient
