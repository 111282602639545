import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from '../PopupButton'

const Container = styled.div`
	display: flex;
	overflow-y: hidden;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	width: 250px;
	padding: 8px;
	z-index: 2;
	font-family: 'Montserrat', sans-serif;
`

const Section = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
`

const OptionsSection = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding: 8px 0 8px 0;
`

const TimeRangeSelector = ({ ranges, onSelect, selectedRange }) => {
	return (
		<Container>
			<Section>
				<OptionsSection>
					{ranges.map(r => (
						<Button
							key={r.value}
							label={r.label}
							isSelected={r.value === selectedRange}
							onClick={() => onSelect(r.value)}
						/>
					))}
				</OptionsSection>
			</Section>
		</Container>
	)
}

TimeRangeSelector.propTypes = {
	ranges: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		})
	).isRequired,
	onSelect: PropTypes.func.isRequired,
	selectedRange: PropTypes.string.isRequired,
}
TimeRangeSelector.defaultProps = {
	onSelect: () => {},
}

export default TimeRangeSelector
