import React, { Fragment, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import take from 'lodash/take'
import takeLast from 'lodash/takeRight'

import MobilePopup from '../MobilePopup'
import TimeRangeSelector from '../TimeRangeSelector'
import { HeaderContainer, ItemHeaderContainer } from './ItemHeaderContainer'

import { CHART_TIME, CHART_TYPE } from '../../utils/chart'

const MobileHeader = ({ timesOptions, onChangeTime, onChangeChartType, onOpenIndicators }) => {
	const [currentTimeOption, setCurrentTimeOption] = useState(CHART_TIME.TIME_1_H)
	const [currentChartOption, setCurrentChartOption] = useState(CHART_TYPE.CANDLE)
	const [rangePopupIsOpen, setRangePopupIsOpen] = useState(false)
	const [firstTimeOptions, setFirstTimeOptions] = useState([])
	const [popupTimeOptions, setPopupTimeOptions] = useState([])

	const handleToogleChart = useCallback(() => {
		if (currentChartOption === CHART_TYPE.CANDLE) {
			// the time of line chart is always 1d
			setCurrentChartOption(CHART_TYPE.LINE)
			handleClickTime(CHART_TIME.TIME_1_D)
		} else {
			setCurrentChartOption(CHART_TYPE.CANDLE)
		}
	}, [currentChartOption])

	const handleClickTime = useCallback(timeOptionValue => {
		setCurrentTimeOption(timeOptionValue)
	}, [])

	const onCloseRangePopup = useCallback(() => {
		setRangePopupIsOpen(false)
	}, [])

	useEffect(() => {
		onChangeChartType(currentChartOption)
	}, [currentChartOption])

	useEffect(() => {
		onChangeTime(currentTimeOption)
	}, [currentTimeOption])

	useEffect(() => {
		const first = take(timesOptions, 3)
		const popupOptions = takeLast(timesOptions, 8)
		const popupSelectedIndex = popupOptions.findIndex(op => op.value === currentTimeOption)
		const indexToRenderInLastOption = popupSelectedIndex > -1 ? popupSelectedIndex : 0
		const lastOption = popupOptions[indexToRenderInLastOption]

		setFirstTimeOptions([...first, lastOption])
		setPopupTimeOptions(popupOptions)
	}, [timesOptions, currentTimeOption])

	const shouldDisplayMoreRangeOptions = timesOptions.length > firstTimeOptions.length

	return (
		<Fragment>
			<HeaderContainer>
				<ItemHeaderContainer
					text={currentChartOption === CHART_TYPE.CANDLE ? 'Line' : 'Candle'}
					onClick={() => handleToogleChart()}
				/>
				{firstTimeOptions.map((timeOption, index) => {
					const isLastOption = index === firstTimeOptions.length - 1
					return (
						<ItemHeaderContainer
							key={`${timeOption.value}-${index}`}
							text={timeOption.label}
							active={currentTimeOption === timeOption.value}
							more={isLastOption}
							onClick={() => {
								if (isLastOption && shouldDisplayMoreRangeOptions) {
									setRangePopupIsOpen(true)
								} else handleClickTime(timeOption.value)
							}}
						/>
					)
				})}
				<ItemHeaderContainer text='Indicators' onClick={() => onOpenIndicators()} />
			</HeaderContainer>
			<MobilePopup isOpen={rangePopupIsOpen} close={onCloseRangePopup}>
				<TimeRangeSelector
					ranges={popupTimeOptions}
					selectedRange={currentTimeOption}
					onSelect={handleClickTime}
				/>
			</MobilePopup>
		</Fragment>
	)
}

MobileHeader.propTypes = {
	timesOptions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		})
	).isRequired,
	onChangeTime: PropTypes.func.isRequired,
	onChangeChartType: PropTypes.func.isRequired,
	onOpenIndicators: PropTypes.func.isRequired,
}

MobileHeader.defaultProps = {
	timesOptions: [
		{ label: '8H', value: CHART_TIME.TIME_8_H },
		{ label: '1D', value: CHART_TIME.TIME_1_D },
		{ label: '1W', value: CHART_TIME.TIME_1_W },
		{ label: '1m', value: CHART_TIME.TIME_1 },
		{ label: '5m', value: CHART_TIME.TIME_5 },
		{ label: '15m', value: CHART_TIME.TIME_15 },
		{ label: '30m', value: CHART_TIME.TIME_30 },
		{ label: '1H', value: CHART_TIME.TIME_1_H },
		{ label: '2H', value: CHART_TIME.TIME_2_H },
		{ label: '4H', value: CHART_TIME.TIME_4_H },
		{ label: '1M', value: CHART_TIME.TIME_1_M },
	],
	onChangeTime: () => {},
	onChangeChartType: () => {},
	onOpenIndicators: () => {},
}

export default MobileHeader
