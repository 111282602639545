// source: events.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_api_annotations_pb = require('./google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.events.Balance', null, global);
goog.exportSymbol('proto.events.ConnectBalances', null, global);
goog.exportSymbol('proto.events.ConnectFills', null, global);
goog.exportSymbol('proto.events.ConnectGetKeypairs', null, global);
goog.exportSymbol('proto.events.ConnectOrderGroups', null, global);
goog.exportSymbol('proto.events.ConnectOrderbookStatus', null, global);
goog.exportSymbol('proto.events.ConnectOrders', null, global);
goog.exportSymbol('proto.events.ConnectStreamTicker', null, global);
goog.exportSymbol('proto.events.DBStatus', null, global);
goog.exportSymbol('proto.events.Fiat', null, global);
goog.exportSymbol('proto.events.HealthCheckResponse', null, global);
goog.exportSymbol('proto.events.KafkaStreamStatus', null, global);
goog.exportSymbol('proto.events.KafkaStreamStatus.PartitionStatus', null, global);
goog.exportSymbol('proto.events.OKTA_CLIENT', null, global);
goog.exportSymbol('proto.events.Order', null, global);
goog.exportSymbol('proto.events.OrderGroup', null, global);
goog.exportSymbol('proto.events.OrderOperation', null, global);
goog.exportSymbol('proto.events.OrderSide', null, global);
goog.exportSymbol('proto.events.OrderStatus', null, global);
goog.exportSymbol('proto.events.OrderbookStatusResponse', null, global);
goog.exportSymbol('proto.events.StreamBalancesResponse', null, global);
goog.exportSymbol('proto.events.StreamFillsResponse', null, global);
goog.exportSymbol('proto.events.StreamGetKeypairsResponse', null, global);
goog.exportSymbol('proto.events.StreamOrderGroupsResponse', null, global);
goog.exportSymbol('proto.events.StreamOrderbookStatusResponse', null, global);
goog.exportSymbol('proto.events.StreamOrdersResponse', null, global);
goog.exportSymbol('proto.events.StreamTickerResponse', null, global);
goog.exportSymbol('proto.events.Ticker', null, global);
goog.exportSymbol('proto.events.TickerTimers', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.OrderbookStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.OrderbookStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.OrderbookStatusResponse.displayName = 'proto.events.OrderbookStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.Ticker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.Ticker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.Ticker.displayName = 'proto.events.Ticker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.Order = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.Order.displayName = 'proto.events.Order';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.Balance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.Balance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.Balance.displayName = 'proto.events.Balance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.OrderGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.OrderGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.OrderGroup.displayName = 'proto.events.OrderGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.ConnectStreamTicker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.ConnectStreamTicker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.ConnectStreamTicker.displayName = 'proto.events.ConnectStreamTicker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.ConnectOrderbookStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.ConnectOrderbookStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.ConnectOrderbookStatus.displayName = 'proto.events.ConnectOrderbookStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.StreamTickerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.StreamTickerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.StreamTickerResponse.displayName = 'proto.events.StreamTickerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.ConnectOrders = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.ConnectOrders, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.ConnectOrders.displayName = 'proto.events.ConnectOrders';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.StreamOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.StreamOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.StreamOrdersResponse.displayName = 'proto.events.StreamOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.StreamOrderbookStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.StreamOrderbookStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.StreamOrderbookStatusResponse.displayName = 'proto.events.StreamOrderbookStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.StreamGetKeypairsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.StreamGetKeypairsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.StreamGetKeypairsResponse.displayName = 'proto.events.StreamGetKeypairsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.ConnectBalances = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.ConnectBalances, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.ConnectBalances.displayName = 'proto.events.ConnectBalances';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.ConnectGetKeypairs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.ConnectGetKeypairs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.ConnectGetKeypairs.displayName = 'proto.events.ConnectGetKeypairs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.StreamBalancesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.events.StreamBalancesResponse.repeatedFields_, null);
};
goog.inherits(proto.events.StreamBalancesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.StreamBalancesResponse.displayName = 'proto.events.StreamBalancesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.ConnectOrderGroups = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.ConnectOrderGroups, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.ConnectOrderGroups.displayName = 'proto.events.ConnectOrderGroups';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.StreamOrderGroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.events.StreamOrderGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.events.StreamOrderGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.StreamOrderGroupsResponse.displayName = 'proto.events.StreamOrderGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.ConnectFills = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.ConnectFills, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.ConnectFills.displayName = 'proto.events.ConnectFills';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.StreamFillsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.StreamFillsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.StreamFillsResponse.displayName = 'proto.events.StreamFillsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.DBStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.DBStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.DBStatus.displayName = 'proto.events.DBStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.KafkaStreamStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.events.KafkaStreamStatus.repeatedFields_, null);
};
goog.inherits(proto.events.KafkaStreamStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.KafkaStreamStatus.displayName = 'proto.events.KafkaStreamStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.KafkaStreamStatus.PartitionStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.KafkaStreamStatus.PartitionStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.KafkaStreamStatus.PartitionStatus.displayName = 'proto.events.KafkaStreamStatus.PartitionStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.HealthCheckResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.events.HealthCheckResponse.repeatedFields_, null);
};
goog.inherits(proto.events.HealthCheckResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.HealthCheckResponse.displayName = 'proto.events.HealthCheckResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.OrderbookStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.events.OrderbookStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.OrderbookStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.OrderbookStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    high: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    low: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    volume: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    open: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    close: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    variation: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    variationPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    priceCurrency: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.OrderbookStatusResponse}
 */
proto.events.OrderbookStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.OrderbookStatusResponse;
  return proto.events.OrderbookStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.OrderbookStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.OrderbookStatusResponse}
 */
proto.events.OrderbookStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHigh(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLow(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVolume(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpen(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClose(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVariation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVariationPercentage(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.OrderbookStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.OrderbookStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.OrderbookStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.OrderbookStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getHigh();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLow();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getVolume();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getOpen();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getClose();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getVariation();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getVariationPercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getPriceCurrency();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
};


/**
 * optional int64 count = 1;
 * @return {number}
 */
proto.events.OrderbookStatusResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.OrderbookStatusResponse} returns this
 */
proto.events.OrderbookStatusResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double high = 2;
 * @return {number}
 */
proto.events.OrderbookStatusResponse.prototype.getHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.OrderbookStatusResponse} returns this
 */
proto.events.OrderbookStatusResponse.prototype.setHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double low = 3;
 * @return {number}
 */
proto.events.OrderbookStatusResponse.prototype.getLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.OrderbookStatusResponse} returns this
 */
proto.events.OrderbookStatusResponse.prototype.setLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double volume = 4;
 * @return {number}
 */
proto.events.OrderbookStatusResponse.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.OrderbookStatusResponse} returns this
 */
proto.events.OrderbookStatusResponse.prototype.setVolume = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double open = 5;
 * @return {number}
 */
proto.events.OrderbookStatusResponse.prototype.getOpen = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.OrderbookStatusResponse} returns this
 */
proto.events.OrderbookStatusResponse.prototype.setOpen = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double close = 6;
 * @return {number}
 */
proto.events.OrderbookStatusResponse.prototype.getClose = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.OrderbookStatusResponse} returns this
 */
proto.events.OrderbookStatusResponse.prototype.setClose = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double variation = 7;
 * @return {number}
 */
proto.events.OrderbookStatusResponse.prototype.getVariation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.OrderbookStatusResponse} returns this
 */
proto.events.OrderbookStatusResponse.prototype.setVariation = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double variation_percentage = 8;
 * @return {number}
 */
proto.events.OrderbookStatusResponse.prototype.getVariationPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.OrderbookStatusResponse} returns this
 */
proto.events.OrderbookStatusResponse.prototype.setVariationPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double price_currency = 9;
 * @return {number}
 */
proto.events.OrderbookStatusResponse.prototype.getPriceCurrency = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.OrderbookStatusResponse} returns this
 */
proto.events.OrderbookStatusResponse.prototype.setPriceCurrency = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.Ticker.prototype.toObject = function(opt_includeInstance) {
  return proto.events.Ticker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.Ticker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.Ticker.toObject = function(includeInstance, msg) {
  var f, obj = {
    high: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    low: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    open: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    close: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    volume: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    tickerTime: (f = msg.getTickerTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.Ticker}
 */
proto.events.Ticker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.Ticker;
  return proto.events.Ticker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.Ticker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.Ticker}
 */
proto.events.Ticker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHigh(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLow(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpen(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClose(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVolume(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTickerTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.Ticker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.Ticker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.Ticker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.Ticker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHigh();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLow();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getOpen();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getClose();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getVolume();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTickerTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional double high = 1;
 * @return {number}
 */
proto.events.Ticker.prototype.getHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.Ticker} returns this
 */
proto.events.Ticker.prototype.setHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double low = 2;
 * @return {number}
 */
proto.events.Ticker.prototype.getLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.Ticker} returns this
 */
proto.events.Ticker.prototype.setLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double open = 3;
 * @return {number}
 */
proto.events.Ticker.prototype.getOpen = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.Ticker} returns this
 */
proto.events.Ticker.prototype.setOpen = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double close = 4;
 * @return {number}
 */
proto.events.Ticker.prototype.getClose = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.Ticker} returns this
 */
proto.events.Ticker.prototype.setClose = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double volume = 5;
 * @return {number}
 */
proto.events.Ticker.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.Ticker} returns this
 */
proto.events.Ticker.prototype.setVolume = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp ticker_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.events.Ticker.prototype.getTickerTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.events.Ticker} returns this
*/
proto.events.Ticker.prototype.setTickerTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Ticker} returns this
 */
proto.events.Ticker.prototype.clearTickerTime = function() {
  return this.setTickerTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Ticker.prototype.hasTickerTime = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.Order.prototype.toObject = function(opt_includeInstance) {
  return proto.events.Order.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.Order} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.Order.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    keypairId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    side: jspb.Message.getFieldWithDefault(msg, 5, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    filled: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    operation: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    keypairName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    feeAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    kind: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.Order}
 */
proto.events.Order.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.Order;
  return proto.events.Order.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.Order}
 */
proto.events.Order.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKeypairId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {!proto.events.OrderSide} */ (reader.readEnum());
      msg.setSide(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFilled(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 9:
      var value = /** @type {!proto.events.OrderOperation} */ (reader.readEnum());
      msg.setOperation(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeypairName(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeeAmount(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.Order.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.Order.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.Order.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKeypairId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSide();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getFilled();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getKeypairName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getFeeAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.events.Order.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.Order} returns this
 */
proto.events.Order.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.events.Order.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.Order} returns this
 */
proto.events.Order.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 keypair_id = 3;
 * @return {number}
 */
proto.events.Order.prototype.getKeypairId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.Order} returns this
 */
proto.events.Order.prototype.setKeypairId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.events.Order.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.Order} returns this
 */
proto.events.Order.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional OrderSide side = 5;
 * @return {!proto.events.OrderSide}
 */
proto.events.Order.prototype.getSide = function() {
  return /** @type {!proto.events.OrderSide} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.events.OrderSide} value
 * @return {!proto.events.Order} returns this
 */
proto.events.Order.prototype.setSide = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional double price = 6;
 * @return {number}
 */
proto.events.Order.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.Order} returns this
 */
proto.events.Order.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double filled = 7;
 * @return {number}
 */
proto.events.Order.prototype.getFilled = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.Order} returns this
 */
proto.events.Order.prototype.setFilled = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double quantity = 8;
 * @return {number}
 */
proto.events.Order.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.Order} returns this
 */
proto.events.Order.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional OrderOperation operation = 9;
 * @return {!proto.events.OrderOperation}
 */
proto.events.Order.prototype.getOperation = function() {
  return /** @type {!proto.events.OrderOperation} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.events.OrderOperation} value
 * @return {!proto.events.Order} returns this
 */
proto.events.Order.prototype.setOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.events.Order.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.events.Order} returns this
*/
proto.events.Order.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Order} returns this
 */
proto.events.Order.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Order.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.events.Order.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.events.Order} returns this
*/
proto.events.Order.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Order} returns this
 */
proto.events.Order.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Order.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string keypair_name = 12;
 * @return {string}
 */
proto.events.Order.prototype.getKeypairName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.Order} returns this
 */
proto.events.Order.prototype.setKeypairName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional double fee_amount = 13;
 * @return {number}
 */
proto.events.Order.prototype.getFeeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.Order} returns this
 */
proto.events.Order.prototype.setFeeAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional string kind = 14;
 * @return {string}
 */
proto.events.Order.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.Order} returns this
 */
proto.events.Order.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.Balance.prototype.toObject = function(opt_includeInstance) {
  return proto.events.Balance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.Balance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.Balance.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tokenId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    balance: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    locked: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    inOrder: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    inWithdraw: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    available: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    abbr: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.Balance}
 */
proto.events.Balance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.Balance;
  return proto.events.Balance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.Balance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.Balance}
 */
proto.events.Balance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTokenId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBalance(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLocked(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInOrder(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInWithdraw(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvailable(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbbr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.Balance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.Balance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.Balance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.Balance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTokenId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLocked();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getInOrder();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getInWithdraw();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getAvailable();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getAbbr();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.events.Balance.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.Balance} returns this
 */
proto.events.Balance.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 account_id = 2;
 * @return {number}
 */
proto.events.Balance.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.Balance} returns this
 */
proto.events.Balance.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 token_id = 3;
 * @return {number}
 */
proto.events.Balance.prototype.getTokenId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.Balance} returns this
 */
proto.events.Balance.prototype.setTokenId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double balance = 4;
 * @return {number}
 */
proto.events.Balance.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.Balance} returns this
 */
proto.events.Balance.prototype.setBalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double locked = 5;
 * @return {number}
 */
proto.events.Balance.prototype.getLocked = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.Balance} returns this
 */
proto.events.Balance.prototype.setLocked = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double in_order = 6;
 * @return {number}
 */
proto.events.Balance.prototype.getInOrder = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.Balance} returns this
 */
proto.events.Balance.prototype.setInOrder = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double in_withdraw = 7;
 * @return {number}
 */
proto.events.Balance.prototype.getInWithdraw = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.Balance} returns this
 */
proto.events.Balance.prototype.setInWithdraw = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double available = 8;
 * @return {number}
 */
proto.events.Balance.prototype.getAvailable = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.Balance} returns this
 */
proto.events.Balance.prototype.setAvailable = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string abbr = 9;
 * @return {string}
 */
proto.events.Balance.prototype.getAbbr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.Balance} returns this
 */
proto.events.Balance.prototype.setAbbr = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.OrderGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.events.OrderGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.OrderGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.OrderGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    keypairId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    keypairName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    side: jspb.Message.getFieldWithDefault(msg, 3, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    filled: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    feeAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    kind: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.OrderGroup}
 */
proto.events.OrderGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.OrderGroup;
  return proto.events.OrderGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.OrderGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.OrderGroup}
 */
proto.events.OrderGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKeypairId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeypairName(value);
      break;
    case 3:
      var value = /** @type {!proto.events.OrderSide} */ (reader.readEnum());
      msg.setSide(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFilled(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeeAmount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.OrderGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.OrderGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.OrderGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.OrderGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeypairId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKeypairName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSide();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getFilled();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getFeeAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 keypair_id = 1;
 * @return {number}
 */
proto.events.OrderGroup.prototype.getKeypairId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.OrderGroup} returns this
 */
proto.events.OrderGroup.prototype.setKeypairId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string keypair_name = 2;
 * @return {string}
 */
proto.events.OrderGroup.prototype.getKeypairName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.OrderGroup} returns this
 */
proto.events.OrderGroup.prototype.setKeypairName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OrderSide side = 3;
 * @return {!proto.events.OrderSide}
 */
proto.events.OrderGroup.prototype.getSide = function() {
  return /** @type {!proto.events.OrderSide} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.events.OrderSide} value
 * @return {!proto.events.OrderGroup} returns this
 */
proto.events.OrderGroup.prototype.setSide = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double price = 4;
 * @return {number}
 */
proto.events.OrderGroup.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.OrderGroup} returns this
 */
proto.events.OrderGroup.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double filled = 5;
 * @return {number}
 */
proto.events.OrderGroup.prototype.getFilled = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.OrderGroup} returns this
 */
proto.events.OrderGroup.prototype.setFilled = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double quantity = 6;
 * @return {number}
 */
proto.events.OrderGroup.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.OrderGroup} returns this
 */
proto.events.OrderGroup.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double fee_amount = 7;
 * @return {number}
 */
proto.events.OrderGroup.prototype.getFeeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.OrderGroup} returns this
 */
proto.events.OrderGroup.prototype.setFeeAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string kind = 8;
 * @return {string}
 */
proto.events.OrderGroup.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.OrderGroup} returns this
 */
proto.events.OrderGroup.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.ConnectStreamTicker.prototype.toObject = function(opt_includeInstance) {
  return proto.events.ConnectStreamTicker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.ConnectStreamTicker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ConnectStreamTicker.toObject = function(includeInstance, msg) {
  var f, obj = {
    keypairId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timeRange: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.ConnectStreamTicker}
 */
proto.events.ConnectStreamTicker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.ConnectStreamTicker;
  return proto.events.ConnectStreamTicker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.ConnectStreamTicker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.ConnectStreamTicker}
 */
proto.events.ConnectStreamTicker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKeypairId(value);
      break;
    case 2:
      var value = /** @type {!proto.events.TickerTimers} */ (reader.readEnum());
      msg.setTimeRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.ConnectStreamTicker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.ConnectStreamTicker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.ConnectStreamTicker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ConnectStreamTicker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeypairId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTimeRange();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 keypair_id = 1;
 * @return {number}
 */
proto.events.ConnectStreamTicker.prototype.getKeypairId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.ConnectStreamTicker} returns this
 */
proto.events.ConnectStreamTicker.prototype.setKeypairId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional TickerTimers time_range = 2;
 * @return {!proto.events.TickerTimers}
 */
proto.events.ConnectStreamTicker.prototype.getTimeRange = function() {
  return /** @type {!proto.events.TickerTimers} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.events.TickerTimers} value
 * @return {!proto.events.ConnectStreamTicker} returns this
 */
proto.events.ConnectStreamTicker.prototype.setTimeRange = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.ConnectOrderbookStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.events.ConnectOrderbookStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.ConnectOrderbookStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ConnectOrderbookStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    keypairId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.ConnectOrderbookStatus}
 */
proto.events.ConnectOrderbookStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.ConnectOrderbookStatus;
  return proto.events.ConnectOrderbookStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.ConnectOrderbookStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.ConnectOrderbookStatus}
 */
proto.events.ConnectOrderbookStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKeypairId(value);
      break;
    case 2:
      var value = /** @type {!proto.events.Fiat} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.ConnectOrderbookStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.ConnectOrderbookStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.ConnectOrderbookStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ConnectOrderbookStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeypairId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 keypair_id = 1;
 * @return {number}
 */
proto.events.ConnectOrderbookStatus.prototype.getKeypairId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.ConnectOrderbookStatus} returns this
 */
proto.events.ConnectOrderbookStatus.prototype.setKeypairId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Fiat currency = 2;
 * @return {!proto.events.Fiat}
 */
proto.events.ConnectOrderbookStatus.prototype.getCurrency = function() {
  return /** @type {!proto.events.Fiat} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.events.Fiat} value
 * @return {!proto.events.ConnectOrderbookStatus} returns this
 */
proto.events.ConnectOrderbookStatus.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.StreamTickerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.events.StreamTickerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.StreamTickerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StreamTickerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tickers: (f = msg.getTickers()) && proto.events.Ticker.toObject(includeInstance, f),
    keypairId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    keypairName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.StreamTickerResponse}
 */
proto.events.StreamTickerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.StreamTickerResponse;
  return proto.events.StreamTickerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.StreamTickerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.StreamTickerResponse}
 */
proto.events.StreamTickerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.events.Ticker;
      reader.readMessage(value,proto.events.Ticker.deserializeBinaryFromReader);
      msg.setTickers(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKeypairId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeypairName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.StreamTickerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.StreamTickerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.StreamTickerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StreamTickerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTickers();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.events.Ticker.serializeBinaryToWriter
    );
  }
  f = message.getKeypairId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getKeypairName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Ticker tickers = 1;
 * @return {?proto.events.Ticker}
 */
proto.events.StreamTickerResponse.prototype.getTickers = function() {
  return /** @type{?proto.events.Ticker} */ (
    jspb.Message.getWrapperField(this, proto.events.Ticker, 1));
};


/**
 * @param {?proto.events.Ticker|undefined} value
 * @return {!proto.events.StreamTickerResponse} returns this
*/
proto.events.StreamTickerResponse.prototype.setTickers = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.StreamTickerResponse} returns this
 */
proto.events.StreamTickerResponse.prototype.clearTickers = function() {
  return this.setTickers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.StreamTickerResponse.prototype.hasTickers = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 keypair_id = 2;
 * @return {number}
 */
proto.events.StreamTickerResponse.prototype.getKeypairId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.StreamTickerResponse} returns this
 */
proto.events.StreamTickerResponse.prototype.setKeypairId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string keypair_name = 3;
 * @return {string}
 */
proto.events.StreamTickerResponse.prototype.getKeypairName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.StreamTickerResponse} returns this
 */
proto.events.StreamTickerResponse.prototype.setKeypairName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.ConnectOrders.prototype.toObject = function(opt_includeInstance) {
  return proto.events.ConnectOrders.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.ConnectOrders} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ConnectOrders.toObject = function(includeInstance, msg) {
  var f, obj = {
    active: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    keypairId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.ConnectOrders}
 */
proto.events.ConnectOrders.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.ConnectOrders;
  return proto.events.ConnectOrders.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.ConnectOrders} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.ConnectOrders}
 */
proto.events.ConnectOrders.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKeypairId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.ConnectOrders.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.ConnectOrders.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.ConnectOrders} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ConnectOrders.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getKeypairId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional bool active = 1;
 * @return {boolean}
 */
proto.events.ConnectOrders.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.events.ConnectOrders} returns this
 */
proto.events.ConnectOrders.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 keypair_id = 2;
 * @return {number}
 */
proto.events.ConnectOrders.prototype.getKeypairId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.ConnectOrders} returns this
 */
proto.events.ConnectOrders.prototype.setKeypairId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.StreamOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.events.StreamOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.StreamOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StreamOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orders: (f = msg.getOrders()) && proto.events.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.StreamOrdersResponse}
 */
proto.events.StreamOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.StreamOrdersResponse;
  return proto.events.StreamOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.StreamOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.StreamOrdersResponse}
 */
proto.events.StreamOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.events.Order;
      reader.readMessage(value,proto.events.Order.deserializeBinaryFromReader);
      msg.setOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.StreamOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.StreamOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.StreamOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StreamOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrders();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.events.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order orders = 1;
 * @return {?proto.events.Order}
 */
proto.events.StreamOrdersResponse.prototype.getOrders = function() {
  return /** @type{?proto.events.Order} */ (
    jspb.Message.getWrapperField(this, proto.events.Order, 1));
};


/**
 * @param {?proto.events.Order|undefined} value
 * @return {!proto.events.StreamOrdersResponse} returns this
*/
proto.events.StreamOrdersResponse.prototype.setOrders = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.StreamOrdersResponse} returns this
 */
proto.events.StreamOrdersResponse.prototype.clearOrders = function() {
  return this.setOrders(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.StreamOrdersResponse.prototype.hasOrders = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.StreamOrderbookStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.events.StreamOrderbookStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.StreamOrderbookStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StreamOrderbookStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderbookStatus: (f = msg.getOrderbookStatus()) && proto.events.OrderbookStatusResponse.toObject(includeInstance, f),
    keypairId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    keypairname: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.StreamOrderbookStatusResponse}
 */
proto.events.StreamOrderbookStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.StreamOrderbookStatusResponse;
  return proto.events.StreamOrderbookStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.StreamOrderbookStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.StreamOrderbookStatusResponse}
 */
proto.events.StreamOrderbookStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.events.OrderbookStatusResponse;
      reader.readMessage(value,proto.events.OrderbookStatusResponse.deserializeBinaryFromReader);
      msg.setOrderbookStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKeypairId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeypairname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.StreamOrderbookStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.StreamOrderbookStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.StreamOrderbookStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StreamOrderbookStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderbookStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.events.OrderbookStatusResponse.serializeBinaryToWriter
    );
  }
  f = message.getKeypairId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getKeypairname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional OrderbookStatusResponse orderbook_status = 1;
 * @return {?proto.events.OrderbookStatusResponse}
 */
proto.events.StreamOrderbookStatusResponse.prototype.getOrderbookStatus = function() {
  return /** @type{?proto.events.OrderbookStatusResponse} */ (
    jspb.Message.getWrapperField(this, proto.events.OrderbookStatusResponse, 1));
};


/**
 * @param {?proto.events.OrderbookStatusResponse|undefined} value
 * @return {!proto.events.StreamOrderbookStatusResponse} returns this
*/
proto.events.StreamOrderbookStatusResponse.prototype.setOrderbookStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.StreamOrderbookStatusResponse} returns this
 */
proto.events.StreamOrderbookStatusResponse.prototype.clearOrderbookStatus = function() {
  return this.setOrderbookStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.StreamOrderbookStatusResponse.prototype.hasOrderbookStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 Keypair_id = 2;
 * @return {number}
 */
proto.events.StreamOrderbookStatusResponse.prototype.getKeypairId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.StreamOrderbookStatusResponse} returns this
 */
proto.events.StreamOrderbookStatusResponse.prototype.setKeypairId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string KeypairName = 3;
 * @return {string}
 */
proto.events.StreamOrderbookStatusResponse.prototype.getKeypairname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.StreamOrderbookStatusResponse} returns this
 */
proto.events.StreamOrderbookStatusResponse.prototype.setKeypairname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.StreamGetKeypairsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.events.StreamGetKeypairsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.StreamGetKeypairsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StreamGetKeypairsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    priceCurrency: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    volume: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    variationPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.StreamGetKeypairsResponse}
 */
proto.events.StreamGetKeypairsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.StreamGetKeypairsResponse;
  return proto.events.StreamGetKeypairsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.StreamGetKeypairsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.StreamGetKeypairsResponse}
 */
proto.events.StreamGetKeypairsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceCurrency(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVolume(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVariationPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.StreamGetKeypairsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.StreamGetKeypairsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.StreamGetKeypairsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StreamGetKeypairsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getPriceCurrency();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getVolume();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getVariationPercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.events.StreamGetKeypairsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.StreamGetKeypairsResponse} returns this
 */
proto.events.StreamGetKeypairsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.events.StreamGetKeypairsResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.StreamGetKeypairsResponse} returns this
 */
proto.events.StreamGetKeypairsResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double price = 3;
 * @return {number}
 */
proto.events.StreamGetKeypairsResponse.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.StreamGetKeypairsResponse} returns this
 */
proto.events.StreamGetKeypairsResponse.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double price_currency = 4;
 * @return {number}
 */
proto.events.StreamGetKeypairsResponse.prototype.getPriceCurrency = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.StreamGetKeypairsResponse} returns this
 */
proto.events.StreamGetKeypairsResponse.prototype.setPriceCurrency = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double volume = 5;
 * @return {number}
 */
proto.events.StreamGetKeypairsResponse.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.StreamGetKeypairsResponse} returns this
 */
proto.events.StreamGetKeypairsResponse.prototype.setVolume = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double variation_percentage = 6;
 * @return {number}
 */
proto.events.StreamGetKeypairsResponse.prototype.getVariationPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.StreamGetKeypairsResponse} returns this
 */
proto.events.StreamGetKeypairsResponse.prototype.setVariationPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.ConnectBalances.prototype.toObject = function(opt_includeInstance) {
  return proto.events.ConnectBalances.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.ConnectBalances} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ConnectBalances.toObject = function(includeInstance, msg) {
  var f, obj = {
    tokenId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.ConnectBalances}
 */
proto.events.ConnectBalances.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.ConnectBalances;
  return proto.events.ConnectBalances.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.ConnectBalances} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.ConnectBalances}
 */
proto.events.ConnectBalances.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTokenId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.ConnectBalances.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.ConnectBalances.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.ConnectBalances} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ConnectBalances.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTokenId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 token_id = 2;
 * @return {number}
 */
proto.events.ConnectBalances.prototype.getTokenId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.ConnectBalances} returns this
 */
proto.events.ConnectBalances.prototype.setTokenId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.ConnectGetKeypairs.prototype.toObject = function(opt_includeInstance) {
  return proto.events.ConnectGetKeypairs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.ConnectGetKeypairs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ConnectGetKeypairs.toObject = function(includeInstance, msg) {
  var f, obj = {
    currency: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.ConnectGetKeypairs}
 */
proto.events.ConnectGetKeypairs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.ConnectGetKeypairs;
  return proto.events.ConnectGetKeypairs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.ConnectGetKeypairs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.ConnectGetKeypairs}
 */
proto.events.ConnectGetKeypairs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.events.Fiat} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.ConnectGetKeypairs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.ConnectGetKeypairs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.ConnectGetKeypairs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ConnectGetKeypairs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional Fiat currency = 1;
 * @return {!proto.events.Fiat}
 */
proto.events.ConnectGetKeypairs.prototype.getCurrency = function() {
  return /** @type {!proto.events.Fiat} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.events.Fiat} value
 * @return {!proto.events.ConnectGetKeypairs} returns this
 */
proto.events.ConnectGetKeypairs.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.events.StreamBalancesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.StreamBalancesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.events.StreamBalancesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.StreamBalancesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StreamBalancesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    balancesList: jspb.Message.toObjectList(msg.getBalancesList(),
    proto.events.Balance.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.StreamBalancesResponse}
 */
proto.events.StreamBalancesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.StreamBalancesResponse;
  return proto.events.StreamBalancesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.StreamBalancesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.StreamBalancesResponse}
 */
proto.events.StreamBalancesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.events.Balance;
      reader.readMessage(value,proto.events.Balance.deserializeBinaryFromReader);
      msg.addBalances(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.StreamBalancesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.StreamBalancesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.StreamBalancesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StreamBalancesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.events.Balance.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Balance balances = 1;
 * @return {!Array<!proto.events.Balance>}
 */
proto.events.StreamBalancesResponse.prototype.getBalancesList = function() {
  return /** @type{!Array<!proto.events.Balance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.events.Balance, 1));
};


/**
 * @param {!Array<!proto.events.Balance>} value
 * @return {!proto.events.StreamBalancesResponse} returns this
*/
proto.events.StreamBalancesResponse.prototype.setBalancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.events.Balance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.events.Balance}
 */
proto.events.StreamBalancesResponse.prototype.addBalances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.events.Balance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.events.StreamBalancesResponse} returns this
 */
proto.events.StreamBalancesResponse.prototype.clearBalancesList = function() {
  return this.setBalancesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.ConnectOrderGroups.prototype.toObject = function(opt_includeInstance) {
  return proto.events.ConnectOrderGroups.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.ConnectOrderGroups} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ConnectOrderGroups.toObject = function(includeInstance, msg) {
  var f, obj = {
    keypairId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    decimals: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.ConnectOrderGroups}
 */
proto.events.ConnectOrderGroups.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.ConnectOrderGroups;
  return proto.events.ConnectOrderGroups.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.ConnectOrderGroups} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.ConnectOrderGroups}
 */
proto.events.ConnectOrderGroups.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKeypairId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDecimals(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.ConnectOrderGroups.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.ConnectOrderGroups.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.ConnectOrderGroups} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ConnectOrderGroups.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeypairId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDecimals();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 keypair_id = 1;
 * @return {number}
 */
proto.events.ConnectOrderGroups.prototype.getKeypairId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.ConnectOrderGroups} returns this
 */
proto.events.ConnectOrderGroups.prototype.setKeypairId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 decimals = 2;
 * @return {number}
 */
proto.events.ConnectOrderGroups.prototype.getDecimals = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.ConnectOrderGroups} returns this
 */
proto.events.ConnectOrderGroups.prototype.setDecimals = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.events.ConnectOrderGroups.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.ConnectOrderGroups} returns this
 */
proto.events.ConnectOrderGroups.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.events.StreamOrderGroupsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.StreamOrderGroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.events.StreamOrderGroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.StreamOrderGroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StreamOrderGroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    buyOrdersList: jspb.Message.toObjectList(msg.getBuyOrdersList(),
    proto.events.OrderGroup.toObject, includeInstance),
    sellOrdersList: jspb.Message.toObjectList(msg.getSellOrdersList(),
    proto.events.OrderGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.StreamOrderGroupsResponse}
 */
proto.events.StreamOrderGroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.StreamOrderGroupsResponse;
  return proto.events.StreamOrderGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.StreamOrderGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.StreamOrderGroupsResponse}
 */
proto.events.StreamOrderGroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.events.OrderGroup;
      reader.readMessage(value,proto.events.OrderGroup.deserializeBinaryFromReader);
      msg.addBuyOrders(value);
      break;
    case 2:
      var value = new proto.events.OrderGroup;
      reader.readMessage(value,proto.events.OrderGroup.deserializeBinaryFromReader);
      msg.addSellOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.StreamOrderGroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.StreamOrderGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.StreamOrderGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StreamOrderGroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuyOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.events.OrderGroup.serializeBinaryToWriter
    );
  }
  f = message.getSellOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.events.OrderGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OrderGroup buy_orders = 1;
 * @return {!Array<!proto.events.OrderGroup>}
 */
proto.events.StreamOrderGroupsResponse.prototype.getBuyOrdersList = function() {
  return /** @type{!Array<!proto.events.OrderGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.events.OrderGroup, 1));
};


/**
 * @param {!Array<!proto.events.OrderGroup>} value
 * @return {!proto.events.StreamOrderGroupsResponse} returns this
*/
proto.events.StreamOrderGroupsResponse.prototype.setBuyOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.events.OrderGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.events.OrderGroup}
 */
proto.events.StreamOrderGroupsResponse.prototype.addBuyOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.events.OrderGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.events.StreamOrderGroupsResponse} returns this
 */
proto.events.StreamOrderGroupsResponse.prototype.clearBuyOrdersList = function() {
  return this.setBuyOrdersList([]);
};


/**
 * repeated OrderGroup sell_orders = 2;
 * @return {!Array<!proto.events.OrderGroup>}
 */
proto.events.StreamOrderGroupsResponse.prototype.getSellOrdersList = function() {
  return /** @type{!Array<!proto.events.OrderGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.events.OrderGroup, 2));
};


/**
 * @param {!Array<!proto.events.OrderGroup>} value
 * @return {!proto.events.StreamOrderGroupsResponse} returns this
*/
proto.events.StreamOrderGroupsResponse.prototype.setSellOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.events.OrderGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.events.OrderGroup}
 */
proto.events.StreamOrderGroupsResponse.prototype.addSellOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.events.OrderGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.events.StreamOrderGroupsResponse} returns this
 */
proto.events.StreamOrderGroupsResponse.prototype.clearSellOrdersList = function() {
  return this.setSellOrdersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.ConnectFills.prototype.toObject = function(opt_includeInstance) {
  return proto.events.ConnectFills.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.ConnectFills} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ConnectFills.toObject = function(includeInstance, msg) {
  var f, obj = {
    keypairId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.ConnectFills}
 */
proto.events.ConnectFills.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.ConnectFills;
  return proto.events.ConnectFills.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.ConnectFills} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.ConnectFills}
 */
proto.events.ConnectFills.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKeypairId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.ConnectFills.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.ConnectFills.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.ConnectFills} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ConnectFills.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeypairId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 keypair_id = 1;
 * @return {number}
 */
proto.events.ConnectFills.prototype.getKeypairId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.ConnectFills} returns this
 */
proto.events.ConnectFills.prototype.setKeypairId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.StreamFillsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.events.StreamFillsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.StreamFillsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StreamFillsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    side: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.StreamFillsResponse}
 */
proto.events.StreamFillsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.StreamFillsResponse;
  return proto.events.StreamFillsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.StreamFillsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.StreamFillsResponse}
 */
proto.events.StreamFillsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 7:
      var value = /** @type {!proto.events.OrderSide} */ (reader.readEnum());
      msg.setSide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.StreamFillsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.StreamFillsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.StreamFillsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StreamFillsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSide();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.events.StreamFillsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.StreamFillsResponse} returns this
 */
proto.events.StreamFillsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string order_id = 2;
 * @return {string}
 */
proto.events.StreamFillsResponse.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.StreamFillsResponse} returns this
 */
proto.events.StreamFillsResponse.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double price = 3;
 * @return {number}
 */
proto.events.StreamFillsResponse.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.StreamFillsResponse} returns this
 */
proto.events.StreamFillsResponse.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double quantity = 4;
 * @return {number}
 */
proto.events.StreamFillsResponse.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.StreamFillsResponse} returns this
 */
proto.events.StreamFillsResponse.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.events.StreamFillsResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.events.StreamFillsResponse} returns this
*/
proto.events.StreamFillsResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.StreamFillsResponse} returns this
 */
proto.events.StreamFillsResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.StreamFillsResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.events.StreamFillsResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.events.StreamFillsResponse} returns this
*/
proto.events.StreamFillsResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.StreamFillsResponse} returns this
 */
proto.events.StreamFillsResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.StreamFillsResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional OrderSide side = 7;
 * @return {!proto.events.OrderSide}
 */
proto.events.StreamFillsResponse.prototype.getSide = function() {
  return /** @type {!proto.events.OrderSide} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.events.OrderSide} value
 * @return {!proto.events.StreamFillsResponse} returns this
 */
proto.events.StreamFillsResponse.prototype.setSide = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.DBStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.events.DBStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.DBStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.DBStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    abbr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    openConnections: jspb.Message.getFieldWithDefault(msg, 2, 0),
    connectionsInUse: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.DBStatus}
 */
proto.events.DBStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.DBStatus;
  return proto.events.DBStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.DBStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.DBStatus}
 */
proto.events.DBStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbbr(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpenConnections(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConnectionsInUse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.DBStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.DBStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.DBStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.DBStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAbbr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpenConnections();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getConnectionsInUse();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string abbr = 1;
 * @return {string}
 */
proto.events.DBStatus.prototype.getAbbr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.DBStatus} returns this
 */
proto.events.DBStatus.prototype.setAbbr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 open_connections = 2;
 * @return {number}
 */
proto.events.DBStatus.prototype.getOpenConnections = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.DBStatus} returns this
 */
proto.events.DBStatus.prototype.setOpenConnections = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 connections_in_use = 3;
 * @return {number}
 */
proto.events.DBStatus.prototype.getConnectionsInUse = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.DBStatus} returns this
 */
proto.events.DBStatus.prototype.setConnectionsInUse = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.events.KafkaStreamStatus.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.KafkaStreamStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.events.KafkaStreamStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.KafkaStreamStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.KafkaStreamStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    partitionList: jspb.Message.toObjectList(msg.getPartitionList(),
    proto.events.KafkaStreamStatus.PartitionStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.KafkaStreamStatus}
 */
proto.events.KafkaStreamStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.KafkaStreamStatus;
  return proto.events.KafkaStreamStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.KafkaStreamStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.KafkaStreamStatus}
 */
proto.events.KafkaStreamStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.events.KafkaStreamStatus.PartitionStatus;
      reader.readMessage(value,proto.events.KafkaStreamStatus.PartitionStatus.deserializeBinaryFromReader);
      msg.addPartition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.KafkaStreamStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.KafkaStreamStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.KafkaStreamStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.KafkaStreamStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartitionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.events.KafkaStreamStatus.PartitionStatus.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.KafkaStreamStatus.PartitionStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.events.KafkaStreamStatus.PartitionStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.KafkaStreamStatus.PartitionStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.KafkaStreamStatus.PartitionStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    partition: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lag: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.KafkaStreamStatus.PartitionStatus}
 */
proto.events.KafkaStreamStatus.PartitionStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.KafkaStreamStatus.PartitionStatus;
  return proto.events.KafkaStreamStatus.PartitionStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.KafkaStreamStatus.PartitionStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.KafkaStreamStatus.PartitionStatus}
 */
proto.events.KafkaStreamStatus.PartitionStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartition(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.KafkaStreamStatus.PartitionStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.KafkaStreamStatus.PartitionStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.KafkaStreamStatus.PartitionStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.KafkaStreamStatus.PartitionStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartition();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLag();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string partition = 1;
 * @return {string}
 */
proto.events.KafkaStreamStatus.PartitionStatus.prototype.getPartition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.KafkaStreamStatus.PartitionStatus} returns this
 */
proto.events.KafkaStreamStatus.PartitionStatus.prototype.setPartition = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 lag = 2;
 * @return {number}
 */
proto.events.KafkaStreamStatus.PartitionStatus.prototype.getLag = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.KafkaStreamStatus.PartitionStatus} returns this
 */
proto.events.KafkaStreamStatus.PartitionStatus.prototype.setLag = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated PartitionStatus partition = 1;
 * @return {!Array<!proto.events.KafkaStreamStatus.PartitionStatus>}
 */
proto.events.KafkaStreamStatus.prototype.getPartitionList = function() {
  return /** @type{!Array<!proto.events.KafkaStreamStatus.PartitionStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.events.KafkaStreamStatus.PartitionStatus, 1));
};


/**
 * @param {!Array<!proto.events.KafkaStreamStatus.PartitionStatus>} value
 * @return {!proto.events.KafkaStreamStatus} returns this
*/
proto.events.KafkaStreamStatus.prototype.setPartitionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.events.KafkaStreamStatus.PartitionStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.events.KafkaStreamStatus.PartitionStatus}
 */
proto.events.KafkaStreamStatus.prototype.addPartition = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.events.KafkaStreamStatus.PartitionStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.events.KafkaStreamStatus} returns this
 */
proto.events.KafkaStreamStatus.prototype.clearPartitionList = function() {
  return this.setPartitionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.events.HealthCheckResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.HealthCheckResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.events.HealthCheckResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.HealthCheckResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.HealthCheckResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    service: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nodesStatusList: jspb.Message.toObjectList(msg.getNodesStatusList(),
    proto.events.DBStatus.toObject, includeInstance),
    kafkaStatus: (f = msg.getKafkaStatus()) && proto.events.KafkaStreamStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.HealthCheckResponse}
 */
proto.events.HealthCheckResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.HealthCheckResponse;
  return proto.events.HealthCheckResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.HealthCheckResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.HealthCheckResponse}
 */
proto.events.HealthCheckResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = new proto.events.DBStatus;
      reader.readMessage(value,proto.events.DBStatus.deserializeBinaryFromReader);
      msg.addNodesStatus(value);
      break;
    case 4:
      var value = new proto.events.KafkaStreamStatus;
      reader.readMessage(value,proto.events.KafkaStreamStatus.deserializeBinaryFromReader);
      msg.setKafkaStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.HealthCheckResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.HealthCheckResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.HealthCheckResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.HealthCheckResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getService();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNodesStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.events.DBStatus.serializeBinaryToWriter
    );
  }
  f = message.getKafkaStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.events.KafkaStreamStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional string service = 1;
 * @return {string}
 */
proto.events.HealthCheckResponse.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.HealthCheckResponse} returns this
 */
proto.events.HealthCheckResponse.prototype.setService = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.events.HealthCheckResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.HealthCheckResponse} returns this
 */
proto.events.HealthCheckResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DBStatus nodes_status = 3;
 * @return {!Array<!proto.events.DBStatus>}
 */
proto.events.HealthCheckResponse.prototype.getNodesStatusList = function() {
  return /** @type{!Array<!proto.events.DBStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.events.DBStatus, 3));
};


/**
 * @param {!Array<!proto.events.DBStatus>} value
 * @return {!proto.events.HealthCheckResponse} returns this
*/
proto.events.HealthCheckResponse.prototype.setNodesStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.events.DBStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.events.DBStatus}
 */
proto.events.HealthCheckResponse.prototype.addNodesStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.events.DBStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.events.HealthCheckResponse} returns this
 */
proto.events.HealthCheckResponse.prototype.clearNodesStatusList = function() {
  return this.setNodesStatusList([]);
};


/**
 * optional KafkaStreamStatus kafka_status = 4;
 * @return {?proto.events.KafkaStreamStatus}
 */
proto.events.HealthCheckResponse.prototype.getKafkaStatus = function() {
  return /** @type{?proto.events.KafkaStreamStatus} */ (
    jspb.Message.getWrapperField(this, proto.events.KafkaStreamStatus, 4));
};


/**
 * @param {?proto.events.KafkaStreamStatus|undefined} value
 * @return {!proto.events.HealthCheckResponse} returns this
*/
proto.events.HealthCheckResponse.prototype.setKafkaStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.HealthCheckResponse} returns this
 */
proto.events.HealthCheckResponse.prototype.clearKafkaStatus = function() {
  return this.setKafkaStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.HealthCheckResponse.prototype.hasKafkaStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.events.OrderSide = {
  BUY: 0,
  SELL: 1
};

/**
 * @enum {number}
 */
proto.events.OrderStatus = {
  OPEN: 0,
  PARTIALLY: 1,
  DONE: 2,
  CANCELED: 3
};

/**
 * @enum {number}
 */
proto.events.OrderOperation = {
  LIMIT: 0,
  MARKET: 1
};

/**
 * @enum {number}
 */
proto.events.Fiat = {
  USD: 0,
  EUR: 1,
  BRL: 2,
  BTC: 3
};

/**
 * @enum {number}
 */
proto.events.TickerTimers = {
  MINUTE_1: 0,
  MINUTE_15: 1,
  HOUR_1: 2,
  HOUR_4: 3,
  DAILY: 4,
  WEEKLY: 5,
  MONTHLY: 6,
  YEARLY: 7
};

/**
 * @enum {number}
 */
proto.events.OKTA_CLIENT = {
  UNKNOWN: 0,
  ANDROID: 1,
  IOS: 2,
  WEB: 3
};

goog.object.extend(exports, proto.events);
