import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
	display: flex;
	flex-direction: column;
`

const IndicatorsRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-top: 4px;
`

const IndicatorLabel = styled.span`
	padding-right: 8px;
	font-size: 10px;
	font-family: 'Montserrat', sans-serif;
	color: ${props => props.color};
`

const MobileIndicatorsLabels = ({ mainIndicators }) => {
	return (
		<Container>
			{mainIndicators.map((ind, index) =>
				ind.isSelected ? (
					<IndicatorsRow key={ind.label}>
						{ind.ids.map((id, index) => {
							const color = ind.colors[index]
							const inputs = ind.inputs[index].join(':')

							return <IndicatorLabel key={id} color={color}>{`${ind.label} ${inputs}`}</IndicatorLabel>
						})}
					</IndicatorsRow>
				) : null
			)}
		</Container>
	)
}

MobileIndicatorsLabels.propTypes = {
	mainIndicators: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.string,
			isSelected: PropTypes.bool,
		})
	).isRequired,
}

export default MobileIndicatorsLabels
