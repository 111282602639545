import React, { Fragment, useCallback, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import TVChartContainer from '../components/TVChartContainer'
import MobilePopup from '../components/MobilePopup'
import MobileIndicatorsSelector from '../components/MobileIndicatorsSelector'
import MobileIndicatorsLabels from '../components/MobileIndicatorsLabels'
import MobileHeader from '../components/MobileHeader'

import { webOptions, webDataFeedConfig } from '../utils/webChart'
import { mobileOptions, mobileDataFeedConfig, INDICATORS_COLORS } from '../utils/mobileChart'
import { createIndicator, setIndicatorsVisible, changeChartRange } from '../utils/chart'

const TVWeb = () => {
	// <---------- Create header custom buttons (timeframes) ---------->
	const onCreateWidget = useCallback(tvWidget => {
		tvWidget.headerReady().then(function () {
			const label = tvWidget.createButton({ align: 'right' })
			label.textContent = 'Time range: '
			label.className = 'label'

			const sixMonthsButton = tvWidget.createButton({ align: 'right' })
			sixMonthsButton.setAttribute('title', '6 Months')
			sixMonthsButton.addEventListener('click', () => changeChartRange('1D', 180, tvWidget))
			sixMonthsButton.textContent = '6m'

			const threeMonthsButton = tvWidget.createButton({ align: 'right' })
			threeMonthsButton.setAttribute('title', '3 Months')
			threeMonthsButton.addEventListener('click', () => changeChartRange('480', 90, tvWidget))
			threeMonthsButton.textContent = '3m'

			const oneMonthButton = tvWidget.createButton({ align: 'right' })
			oneMonthButton.setAttribute('title', '1 Month')
			oneMonthButton.addEventListener('click', () => changeChartRange('120', 30, tvWidget))
			oneMonthButton.textContent = '1m'

			const fiveDaysButton = tvWidget.createButton({ align: 'right' })
			fiveDaysButton.setAttribute('title', '5 Days')
			fiveDaysButton.addEventListener('click', () => changeChartRange('5', 5, tvWidget))
			fiveDaysButton.textContent = '5d'

			const oneDayButton = tvWidget.createButton({ align: 'right' })
			oneDayButton.setAttribute('title', '1 Day')
			oneDayButton.addEventListener('click', () => changeChartRange('1', 1, tvWidget))
			oneDayButton.textContent = '1d'
		})
	}, [])

	return <TVChartContainer options={webOptions} dataFeedConfig={webDataFeedConfig} onCreateWidget={onCreateWidget} />
}

const TVMobile = () => {
	const [tv, setTv] = useState(null)
	const [isIndicatorsOpen, setIsIndicatorsOpen] = useState(false)
	const [mainIndicatorsOptions, setMainIndicatorsOptions] = useState([])

	const onCreateWidget = useCallback(async tvWidget => {
		const MA_9 = await createIndicator(tvWidget, 'Moving Average', [9, 'close', 0], {
			'plot.color': INDICATORS_COLORS.MA_9_0,
		})
		const MA_12 = await createIndicator(tvWidget, 'Moving Average', [12, 'close', 0], {
			'plot.color': INDICATORS_COLORS.MA_12_0,
		})
		const MA_30 = await createIndicator(tvWidget, 'Moving Average', [30, 'close', 0], {
			'plot.color': INDICATORS_COLORS.MA_30_0,
		})
		const EMA_9 = await createIndicator(tvWidget, 'Moving Average Exponential', [9, 'close', 0], {
			'plot.color': INDICATORS_COLORS.EMA_9_0,
		})
		const EMA_12 = await createIndicator(tvWidget, 'Moving Average Exponential', [12, 'close', 0], {
			'plot.color': INDICATORS_COLORS.EMA_12_0,
		})
		const EMA_30 = await createIndicator(tvWidget, 'Moving Average Exponential', [30, 'close', 0], {
			'plot.color': INDICATORS_COLORS.EMA_30_0,
		})
		const BOLL = await createIndicator(tvWidget, 'Bollinger Bands', [20, 2.0], {
			'styles.plot_0.color': INDICATORS_COLORS.BOLL,
		})
		// const ICHIMOKU = await createIndicator(tvWidget, 'Ichimoku Cloud', [9, 26, 52, 26], {
		// 	// 'styles.plot_1.color': INDICATORS_COLORS.ICHIMOKU,
		// 	// 'styles.plot_2.color': INDICATORS_COLORS.ICHIMOKU,
		// 	// 'styles.plot_3.color': INDICATORS_COLORS.ICHIMOKU,
		// 	// 'styles.plot_4.color': INDICATORS_COLORS.ICHIMOKU,
		// })
		const mainOptions = [
			{
				label: 'MA',
				ids: [MA_9, MA_12, MA_30],
				isSelected: false,
				inputs: [
					[9, 0],
					[12, 0],
					[30, 0],
				],
				colors: [INDICATORS_COLORS.MA_9_0, INDICATORS_COLORS.MA_12_0, INDICATORS_COLORS.MA_30_0],
			},
			{
				label: 'EMA',
				ids: [EMA_9, EMA_12, EMA_30],
				isSelected: false,
				inputs: [
					[9, 0],
					[12, 0],
					[30, 0],
				],
				colors: [INDICATORS_COLORS.EMA_9_0, INDICATORS_COLORS.EMA_12_0, INDICATORS_COLORS.EMA_30_0],
			},
			{
				label: 'BOLL',
				ids: [BOLL],
				isSelected: false,
				inputs: [[20, 2]],
				colors: [INDICATORS_COLORS.BOLL],
			},
			// {
			// 	label: 'ICHIMOKU',
			// 	ids: [ICHIMOKU],
			// 	isSelected: false,
			// 	inputs: [[9, 26, 52, 26]],
			// 	colors: [INDICATORS_COLORS.ICHIMOKU],
			// },
		]
		mainOptions.forEach(op => op.ids.forEach(id => setIndicatorsVisible(tvWidget, id, false)))
		setMainIndicatorsOptions(mainOptions)

		setTv(tvWidget)
	}, [])

	const onHideMainIndicators = useCallback(() => {
		const newOptions = mainIndicatorsOptions.map(ind => {
			ind.ids.forEach(id => {
				setIndicatorsVisible(tv, id, false)
			})
			return { ...ind, isSelected: false }
		})
		setMainIndicatorsOptions(newOptions)
	}, [tv, mainIndicatorsOptions])

	const onSelectMainIndicator = useCallback(
		ind => {
			const newOptions = mainIndicatorsOptions.map(item => {
				const isSelected = ind.label === item.label
				item.ids.forEach(id => {
					setIndicatorsVisible(tv, id, isSelected)
				})
				return { ...item, isSelected }
			})
			setMainIndicatorsOptions(newOptions)
		},
		[tv, mainIndicatorsOptions]
	)

	const changeTime = useCallback(
		timeValue => {
			const chart = tv.activeChart()

			chart.setResolution(timeValue)
		},
		[tv]
	)

	const changeChart = useCallback(
		chartType => {
			const chart = tv.activeChart()

			chart.setChartType(chartType)
		},
		[tv]
	)

	return (
		<Fragment>
			{tv != null && (
				<MobileHeader
					onChangeChartType={chartType => changeChart(chartType)}
					onChangeTime={timeValue => changeTime(timeValue)}
					onOpenIndicators={() => setIsIndicatorsOpen(true)}
				/>
			)}
			<MobileIndicatorsLabels mainIndicators={mainIndicatorsOptions} />
			<TVChartContainer
				options={mobileOptions}
				dataFeedConfig={mobileDataFeedConfig}
				onCreateWidget={onCreateWidget}
			/>
			<MobilePopup isOpen={isIndicatorsOpen} close={() => setIsIndicatorsOpen(false)}>
				<MobileIndicatorsSelector
					onHideMainIndicators={onHideMainIndicators}
					mainIndicators={mainIndicatorsOptions}
					onSelectMainIndicator={onSelectMainIndicator}
				/>
			</MobilePopup>
		</Fragment>
	)
}

const Routes = () => {
	return (
		<Router>
			<Switch>
				<Route exact path='/mobile' component={TVMobile} />
				<Route exact path='/web' component={TVWeb} />
			</Switch>
		</Router>
	)
}

export default Routes
