import React, { useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import Datafeed from './api'
import { widget as Widget } from '../charting_library'
import useQuery from '../../routes/useQuery'
import { TIMEZONES } from '../../utils/chart'

const TVChartContainer = props => {
	let tradingViewWidget = useRef(null)
	const queryParams = useQuery()

	const kpId = queryParams.get('kpid')
	const kpName = queryParams.get('kpname') || 'KEYPAIR'
	const kpPrecision = queryParams.get('precision')

	const getSavedChart = useCallback(() => {
		try {
			const loadedChart = localStorage.getItem('tv_lastTemplate')

			if (loadedChart) {
				const chartObj = JSON.parse(loadedChart)
				return chartObj
			}
		} catch (error) {
			return null
		}
	}, [])

	const resetSymbol = useCallback(widget => {
		widget?.activeChart()?.setSymbol(
			JSON.stringify({
				ID: kpId,
				Name: kpName,
				Decimals: kpPrecision,
			})
		)
	}, [])

	useEffect(() => {
		const chartOptions = {
			symbol: JSON.stringify({ ID: `${kpId}`, Name: kpName, Precision: kpPrecision }),
			datafeed: Datafeed(props.dataFeedConfig),
			interval: props.interval,
			container: props.containerId,
			library_path: props.libraryPath,
			locale: props.locale || 'en',
			...props.options,
		}

		const tvWidget = new Widget(chartOptions)
		tradingViewWidget = tvWidget

		tvWidget.onChartReady(() => {
			props.onCreateWidget(tvWidget)

			// <---------- Load and store chart changes ---------->
			tvWidget?.activeChart().dataReady(() => {
				const loadedChart = getSavedChart()
				if (loadedChart) {
					tvWidget.activeChart().applyStudyTemplate(loadedChart)
				}
			})

			tvWidget.subscribe('onAutoSaveNeeded', () => {
				const template = tvWidget?.activeChart().createStudyTemplate({ saveSymbol: false, saveInterval: true })
				localStorage.setItem('tv_lastTemplate', JSON.stringify(template))
			})

			// <----------  Avoid loading errors ---------->
			tvWidget
				?.activeChart()
				.onIntervalChanged()
				.subscribe(null, () => resetSymbol(tvWidget))

			tvWidget
				.activeChart()
				.onChartTypeChanged()
				.subscribe(null, () => resetSymbol(tvWidget))

			// <---------- Set user timezone ---------->
			const date = new Date()
			const offset = date.getTimezoneOffset()
			const currentUTCHour = offset / 60
			const currentTimezone = TIMEZONES?.find(timezone => timezone.hour === currentUTCHour)
			if (currentTimezone) tvWidget?.activeChart().setTimezone(currentTimezone.name)
		})

		window.tvWidget = tvWidget

		return () => {
			if (tradingViewWidget.current) {
				tradingViewWidget.current?.remove()
				tradingViewWidget.current = null
			}
		}
	}, [props?.options, kpId, kpPrecision])

	return <div id={props.containerId} className='klever-tradingview-container' />
}

TVChartContainer.defaultProps = {
	symbol: '{"ID":"1","Name":"KLV-USDT"}',
	interval: '60',
	containerId: 'tv_chart_container',
	libraryPath: '/charting_library/',
	chartsStorageApiVersion: '1.1',
	clientId: 'tradingview.com',
	userId: 'public_user_id',
	fullscreen: false,
	autosize: true,
	locale: 'en',
	studiesOverrides: {},
	selectedKeypair: { ID: 1, Name: 'KLV-USDT' },
	dataFeedConfig: {
		supported_resolutions: ['1', '15', '60', '1D', '1W'],
	},
	onCreateWidget: () => {},
}

TVChartContainer.propTypes = {
	options: PropTypes.object.isRequired,
	symbol: PropTypes.string,
	interval: PropTypes.string,
	containerId: PropTypes.string,
	libraryPath: PropTypes.string,
	chartsStorageApiVersion: PropTypes.string,
	clientId: PropTypes.string,
	userId: PropTypes.string,
	fullscreen: PropTypes.bool,
	autosize: PropTypes.bool,
	locale: PropTypes.string,
	studiesOverrides: PropTypes.object,
	selectedKeypair: PropTypes.object,
	dataFeedConfig: PropTypes.shape({
		supported_resolutions: PropTypes.arrayOf(PropTypes.string).isRequired,
	}),
	onCreateWidget: PropTypes.func,
}

export default TVChartContainer
