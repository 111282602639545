import { EventsServiceClient } from './protoBuffers/events_grpc_web_pb.js'
const grpc = {}
grpc.web = require('grpc-web')

class GenericClient {
	constructor() {
		this.eventsService = new EventsServiceClient(process.env.REACT_APP_EVENTS_API)
	}

	toPascalCase(unserialized) {
		const entries = Object.entries(unserialized).map(r => {
			let key = r[0]
			let value = r[1]
			key = key.replace(key[0], key[0].toUpperCase()) // Camel to Pascal
			key = key.replace(/Id$/g, 'ID') // Id to ID

			if (typeof value === 'object') {
				value = this.toPascalCase(value)
			}

			return [key, value]
		})

		return Object.fromEntries(entries)
	}

	serializeResponse(response) {
		const unserialized = response.toObject()
		return this.toPascalCase(unserialized)
	}
}

export default GenericClient
